import { defineStore } from 'pinia'

export const useInstantSurveyStore = defineStore('instantSurvey', {
  state: () => {
    return { 
      data: {}
    }
  },
  getters: {},
  actions: {
    setData(options: any) {
      this.data = options.data;
    },
    reset() {
      this.data = {};
    }
  },
  persist: true
});
<template>
  <div class="instant-survey-page-setting">
    <a-form class="py-5" style="overflow: hidden;">
      <a-form-item>
        <a-row :gutter="device.mobile ? 12 : 24">
          <a-col :lg="{ span: 6, offset: 3 }" :xs="{ span: 9, offset: 1 }">
            <div class="mb-1" :style="{ fontSize: '1rem', fontWeight: '500' }">填答連結</div>
            <div :style="{ color: '#757575' }">問卷的填答連結</div>
          </a-col>
          <a-col :lg="{ span: 12 }" :xs="{ span: 14 }">
            <a-input-group compact>
              <a-input v-model:value="liffUrl" style="width: calc(100% - 32px)" />
              <a-tooltip title="複製網址">
                <a-button @click="copyLink">
                  <template #icon><CopyOutlined /></template>
                </a-button>
              </a-tooltip>
            </a-input-group>
          </a-col>
        </a-row>
      </a-form-item>

      <a-divider></a-divider>

      <a-form-item>
        <a-row :gutter="device.mobile ? 12 : 24">
          <a-col :lg="{ span: 6, offset: 3 }" :xs="{ span: 9, offset: 1 }">
            <div class="mb-1" :style="{ fontSize: '1rem', fontWeight: '500' }">名稱</div>
            <div :style="{ color: '#757575' }">此即時調查的名稱</div>
          </a-col>
          <a-col :lg="{ span: 12 }" :xs="{ span: 14 }">
            <a-input v-model:value="formState.name" />
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item>
        <a-row :gutter="device.mobile ? 12 : 24">
          <a-col :lg="{ span: 6, offset: 3 }" :xs="{ span: 9, offset: 1 }">
            <div class="mb-1" :style="{ fontSize: '1rem', fontWeight: '500' }">開放填答</div>
            <div :style="{ color: '#757575' }">學員可以對此調查進行回覆</div>
          </a-col>
          <a-col :lg="{ span: 12 }" :xs="{ span: 14 }">
            <a-switch v-model:checked="formState.is_open" />
          </a-col>
        </a-row>
      </a-form-item>

      <!-- TODO: 暫時不開放此功能 -->
      <!-- <a-form-item>
        <a-row :gutter="device.mobile ? 12 : 24">
          <a-col :lg="{ span: 6, offset: 3 }" :xs="{ span: 9, offset: 1 }">
            <div class="mb-1" :style="{ fontSize: '1rem', fontWeight: '500' }">顯示統計</div>
            <div :style="{ color: '#757575' }">學員可以查看此份調查的統計</div>
          </a-col>
          <a-col :lg="{ span: 12 }" :xs="{ span: 14 }">
            <a-switch v-model:checked="formState.is_show_stat" />
          </a-col>
        </a-row>
      </a-form-item> -->

      <a-form-item>
        <a-row :gutter="device.mobile ? 12 : 24">
          <a-col :lg="{ span: 6, offset: 3 }" :xs="{ span: 9, offset: 1 }">
            
          </a-col>
          <a-col :lg="{ span: 12 }" :xs="{ span: 14 }">
            <a-button type="primary" @click="handleUpdate">更新設定</a-button>
          </a-col>
        </a-row>
      </a-form-item>

      <a-divider />

      <a-form-item>
        <a-row :gutter="device.mobile ? 12 : 24">
          <a-col :lg="{ span: 6, offset: 3 }" :xs="{ span: 9, offset: 1 }">
            <div class="mb-1" :style="{ fontSize: '1rem', fontWeight: '500' }">進階</div>
          </a-col>
          <a-col :lg="{ span: 12 }" :xs="{ span: 14 }">
            <a-button type="primary" danger :style="{ backgroundColor: '#ff4d4f' }">刪除調查</a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { CopyOutlined } from '@ant-design/icons-vue'
import { copyText } from 'vue3-clipboard'
import { useInstantSurveyStore } from './store/index'
import api from '@/apis'

export default defineComponent({
  components: {
    CopyOutlined
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();
    
    /* Store */
    const instantSurveyStore = useInstantSurveyStore();

    /* Loading */
    const updating = ref(false);

    /* Form */
    const formState = ref({ ...instantSurveyStore.data });

    /* Api */
    const getInstantSurveyData = (async () => {
      const response = await api.v1.course.findOneInstantSurvey(route.params.course_id, route.params.id);
      instantSurveyStore.setData({ data: response.data.result });
    });

    return {
      device,

      /* Store */
      instantSurveyStore,

      /* Loading */
      updating,

      /* Form */
      formState,

      /* Api */
      getInstantSurveyData
    }
  },
  computed: {
    liffUrl() {
      return `https://liff.line.me/1656767944-EB4olNPn?course_id=${this.$route.params.course_id}&id=${this.$route.params.id}`
    }
  },
  methods: {
    async handleUpdate() {
      const err = this._checkRequired();
      if (err) {
        message.error(err);
        return
      }

      this.updating = true;

      try {
        await api.v1.course.updateOneInstantSurvey(this.$route.params.course_id, this.$route.params.id, {
          name: this.formState.name,
          is_open: this.formState.is_open,
          is_show_stat: this.formState.is_show_stat
        });
        message.success('更新即時問卷設定成功');
      } catch (error) {
        console.log(error);
        message.error('更新即時問卷設定發生錯誤，請稍後再試');
      } finally {
        this.getInstantSurveyData();
        this.updating = false;
      }
    },
    _checkRequired() {
      let err = '';

      if (!this.formState.name) {
        err = '請輸入名稱';
      }

      return err
    },

    /* Copy */
    copyLink() {
      copyText(this.liffUrl, undefined, (error, event) => {
        if (error) {
          message.error('複製連結發生錯誤，請稍後再試');
          console.log(error)
        } else {
          message.success('複製連結成功');
          console.log(event)
        }
      });
    }
  }
})
</script>

<style lang="scss">
.instant-survey-page-setting {
  width: 100%;
  margin: 0 auto;

  /* md */
  @media screen and (min-width: 970px) {
    width: 600px;
  }
}
</style>